import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import { getUser, isLoggedIn, logout } from "../services/auth"

const Header = ({ siteTitle }) => {
  const [showMenu, setShowMenu] = useState(false)
  const onClickMenuButton = useCallback(e => {
    e.preventDefault()
    setShowMenu(v => !v)
  }, [])

  return (
    <header>
      <nav className="flex items-center justify-between flex-wrap bg-teal-500 p-6">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <Link to="/" className="font-semibold text-xl tracking-tight">
            {siteTitle}
          </Link>
        </div>
        <div className="block sm:hidden">
          <button
            className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
            onClick={onClickMenuButton}
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className={[
            "w-full block flex-grow sm:flex sm:items-center sm:w-auto",
            showMenu ? "" : "hidden",
            "sm:block",
          ]
            .filter(Boolean)
            .join(" ")}
        >
          <div className="text-sm sm:flex-grow">
            <Link
              to="/download"
              className="block mt-4 sm:inline-block sm:mt-0 text-teal-200 hover:text-white mr-4"
            >
              Mobile App
            </Link>
          </div>
          <div>
            <Link
              to="/app/"
              className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 sm:mt-0"
            >
              Web App
            </Link>
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
